.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.menu-item {
  background-color: #ffffff;
}
.menu-item:hover {
  background-color: lightgrey;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}
.control-panel {
  position: absolute;
  left:0;
  width: 360px;
  height: calc(100% - 64px);
  opacity: 1;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 3px 6px;
  margin: 1px;
  font-size: 10px;
  white-space: nowrap;
  line-height: 2;
  color: #6b6b76;
  outline: none;
  overflow-x: auto;
  overflow-y: scroll;
  resize: horizontal;
  z-index: 100;
}
.js-plotly-plot .plotly .main-svg {
background-color: rgba(255,255,255,0)!important
}

.jodit-container.jodit.jodit_theme_default.jodit-wysiwyg_mode.jodit_fullsize {
  z-index: 1999999;
  overflow-y: scroll!important;
}
.jodit-container jodit jodit_theme_default jodit-wysiwyg_mode {
  z-index: 1999999;
  overflow-y: scroll!important;
}
.ui.checkbox .box:after, .ui.checkbox label:after{
  top:-5px!important;
}
.labelwelc {
  padding: 0px;
  margin: 0px;
  /* border: medium solid yellow; */
  border: none !important;
}
.react-checkbox-tree {
  display: block;
  font-size: 16px;
}
.cheb * {
  transform: scale(0.75);
}
.sz90 * {
  transform: scale(0.90);
  cursor: pointer;
}
.sz90:hover * {
  background-color: darkgrey;
}
.sz70 * {
  transform: scale(0.70);
  cursor: pointer;
}
.maintain {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 99999;
  text-align: center;
  line-height:100%;
  opacity: 80%;
}
.oil {
  position: absolute;
  width: 9%;
  display:block;
  left:0;
  bottom:3px;
  height: 65px;
  background-color: #ffffff;
  line-height:100%;
  opacity: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 2;
}
.geotiff-filter {
  position: absolute;
  width: 220px;
  height: 180px;
  z-index: inherit;
  display: block;
  background-color: #ffffff;
}
.perm-row {
  height:23px;
}
.graticule {
  position: absolute;
  width: 240px;
  display:block;
  right: 40px;
  top:280px;
  height: auto;
  padding: 10px;
  background-color: #ffffff;
  line-height:100%;
  opacity: 100%;
  background-color: hsla(0,0%,100%,.95);
}
.reserves-preview{
  width: 450px;
  height: 400px;
  display: block;
  position: absolute;
  background-color: white;
  z-index: 9999999;
}
.map-reserves {
  display: none;
  height: 50%;
  width:50%;
  bottom: 0%;
  right:25%;
  opacity: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 8;
}
.latlng {
  position: absolute;
  width: 135px;
  display:table;
  right: 100px;
  bottom: 30px;
  height: 20px;
  line-height: 100%;
  opacity: 100%;
  background-color: hsla(0,0%,100%,.75);
  font-size: 10px;
  border: 2px solid #333;
  border-top: #333;
  padding:5px;
  color: #333;
  box-sizing: border-box;
  text-align: center;
}
.div-loader {
  height: 100%;
  width: 100%;
  position: absolute;
}
.div-landing {
  top:6%;
  height: 94%;
  width: 100%;
  position: absolute;
  z-index:99999999;

  background-color: rgba(0, 0, 0, .2);
}
.gif-loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.geocoding {
  position: absolute;
  right: 3%;
  top: 0%;
  height: 37px;
  width: 200px;
  z-index: 99999;
}
.geocodingContextMenu {
  position: absolute;
  height: 20px;
  width: 105px;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 99999;
}
.landing-top-menu {
  background-color: rgba(255,255,255,.7);
  backdrop-filter: blur(15px);
  position: fixed!important;
  top: 0px;
  height: 64px;
  width: 100%;
  z-index: 99999999;
}
.geocodingContextMenuSearch {
  position: absolute;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 5px 10px;
  margin: 5px;
  color: #6b6b76;
  outline: none;
  font-size: 12px;
  line-height: 2;
  height: fit-content;
  max-height: 500px;
  overflow-y: scroll;
  z-index: 99999;
}
.geocodinglist {
  position: absolute;
  right: 3%;
  top: 31px;
  background-color: rgba(255, 255, 255, 0.85);
  width: 200px;
  padding: 5px 10px;
  margin: 5px;
  color: #6b6b76;
  outline: none;
  font-size: 12px;
  line-height: 2;
  height: fit-content;
  max-height: 500px;
  overflow-y: scroll;
  z-index: 99999;
}
.th-Stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #acacac;
}
.net-icon{
  z-index: 99;
}
.maplibregl-ctrl-top-right{
  z-index: 99;
}
.contextmenu {
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  padding: 5px 0 5px 0;
  width: 150px;
  height: auto;
  margin: 0;
  /* use absolute positioning  */
  position: fixed;
  list-style: none;
  box-shadow: 0 0 2px 1px #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
}
.MuiInputBase-root {
  max-width: 60%;
}
.login {
  position: absolute;
  display:none;
  top: 30%;
  left: 50%;
  width: 210px;
  max-height: 500px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 5px 10px;
  margin: 5px;
  font-size: 12px;
  line-height: 2;
  color: #6b6b76;
  outline: none;
  overflow-y: scroll;
  z-index: 999999;
}
.profile {
  display: none;
  width: 32%;
  height: 100%;
  left:68%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  overflow-x: scroll;
  z-index: 9999;
}
.point {
  position: absolute;
  display:block;
  top: 22%;
  right: 3%;
  width: 120px;
  height: 180px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 5px 10px;
  margin: 5px;
  font-size: 12px;
  line-height: 2;
  color: #6b6b76;
  outline: none;
  overflow-y: scroll;
  z-index: 999999;
}
.mapboxgl-popup-content {
  overflow-y: scroll;
  overflow-x: scroll;
  max-width: 250px;
  max-height: 200px;
}
.mapboxgl-popup-content::-webkit-scrollbar {
  display: none;
}
.search {
  display:none;
  position: absolute;
  bottom: 10px;
  left: 5px;
  width: 210px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 5px 10px;
  margin: 5px;
  color: #6b6b76;
  outline: none;
  font-size: 12px;
  line-height: 2;
  height: fit-content;
  max-height: 500px;
  overflow-y: scroll;
  z-index: 9999999;
}
.legends {
  display:none;
  position: absolute;
  bottom: 10px;
  left: 5px;
  width: 250px;
  height: 400px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 3px 6px;
  margin: 3px;
  font-size: 10px;
  line-height: 2;
  color: #6b6b76;
  outline: none;
  z-index: 99999999;
}
.image {
  display: none;
  position: absolute;
  top: 60px;
  left: 500px;
  width: 260px;
  height: 110px;
  max-height: 500px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 5px 10px;
  margin: 5px;
  font-size: 12px;
  line-height: 2;
  color: #6b6b76;
  outline: none;
  overflow-y: scroll;
  z-index: 9999999;
}
#file-holder {
  border: 5px dashed #ccc;
  width: 300px;
  height: 50px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: 20px;
  color: #777;
  margin: 0 auto;
}
#holder-holder {
  padding: 20px;
  display: table;
}
#sample-images .img-thumbnail {
  max-width: 120px;
  max-height: 120px;
}
#file-holder.hover { border: 10px dashed #333; }
#main-canvas {
  width:100%;
  height: 100%;
}
#drop-area {
  width: 80%;
  /*border: 2px dashed #aaa;*/
}
.shape {
  display: none;
  position: absolute;
  top: 2px;
  left: 700px;
  width: 250px;
  height: 250px;
  max-height: 500px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 5px 10px;
  margin: 5px;
  font-size: 12px;
  line-height: 2;
  color: #6b6b76;
  outline: none;
  overflow-y: scroll;
  z-index: 9999999;
}
.filterItemList {
  display:none;
  position:absolute ;
  bottom: 0px;
  width: 100%;
  min-height: 100px;
  height: 300px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 2px 4px;
  font-size: 12px;
  line-height: 2;
  color: #6b6b76;
  overflow: scroll;
  outline: none;
  resize: vertical;
  z-index: 9999999;
}
.lasso {
  display:none;
  position:absolute ;
  bottom: 0px;
  left: 30%;
  width: 360px;
  height: 170px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 2px 4px;
  margin: 20px;
  font-size: 12px;
  line-height: 2;
  color: #6b6b76;
  outline: none;
  z-index: 999999;
}
.ui.animated.celled.selection.list{
  overflow-y: scroll;
  max-height: 140px;
}
.redact {
  display:none;
  position:absolute ;
  bottom: 40px;
  right: 20px;
  width: 350px;
  height: fit-content;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 2px 4px;
  margin: 20px;
  font-size: 12px;
  line-height: 2;
  color: #6b6b76;
  outline: none;
  z-index: 999999;
}
.filter {
  position: absolute;
  top: 40px;
  right: 20px;
  width: 240px;
  height: 350px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 12px;
  line-height: 2;
  color: #ffffff;
  outline: none;
  z-index: 999999;
}

form {
  max-width: 500px;
  margin: 0 auto;
}

h1 {
  font-weight: 100;
  color: white;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

.form {
  background: #ffffff;
  max-width: 400px;
  margin: 0 auto;
}

p {
  color: #000000!important;
}

p::before {
  display: inline;
  content: " "!important;
}
.mydiv p { color: #000; }
.mydiv p::before {
  content: ""!important;
}
.jodit-wysiwyg p { color: #000; }
.jodit-wysiwyg p::before {
  content: ""!important;
}
.jodit-wysiwyg h1 {
  color: #000000;
  text-decoration:none;
}
.jodit-workplace *{
  overflow-y: scroll!important;
}
.jodit-toolbar-button__text p { color: #000; }
.jodit-toolbar-button__text p::before {
  content: ""!important;
}
.jodit-toolbar-button__text h1 {
  color: #000000;
  text-decoration:none;
}
input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  color: black;
  font-size: 14px;
  font-weight: 200;
}

input[type="submit"] {
  background: #41373a;
  color: rgb(255, 249, 249);
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

input[type="submit"]:hover {
  background: #bf1650;
}

input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}
.AppForm {
  max-width: 600px;
  margin: 0 auto;
}
.slider-horizontal {
  left: 10px;
  display: block;
  flex: 1;
}
.ui.slider.checkbox {
  top: 2px;
}
.slider-horizontal .rangeslider-horizontal {
  left: 10px;
  display: block;
  height: 10px;
  width: 152px;
}
.rangeslider, .rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.4);
}
.slider-horizontal .rangeslider__handle {
  width: 5px;
  height: 10px;
}
.second-row {position: absolute; top: 100px; left: 0; right: 0; bottom: 0; background-color: white }
.second-row iframe {display: block; width: 100%; height: 100%; border: none;}
.rangeslider .rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0,0,0,.4), 0 -1px 3px rgba(0,0,0,.4);
}
.transparent {
  background-color: transparent!important;
}
.iteal {
  color: #18bfbc;
  background-color: transparent!important;
}
.ired {
  color: #bf1430;
  background-color: transparent!important;
}
.map-overlay {
  position: absolute;
  width: 14%;
  top: 64px;
  left:250px;
  white-space: nowrap;
  background-color: #fff;
  height: fit-content;
  overflow: hidden;
  z-index: 9999999;
}
.map-overlay-mobile {
  top: 56px;
  position: absolute;
  height: 100px;
  width: 45%;
  left:17%;
  white-space: nowrap;
  background-color: #fff;
  overflow-y : hidden;
  overflow-y: scroll;
  z-index: 9999999;
}
.ui.search.dropdown.active>input.search, .ui.search.dropdown.visible>input.search{
  display: block;
}
.map-overlay fieldset {
  display: inline-block;
  background: #ddd;
  border: none;
  padding: 10px;
  margin: 0;
}

.map-overlay .listing {
  overflow: auto;
  height: fit-content;
}

.map-overlay .listing > * {
  display: inline-block;
  padding: 5px 10px;
  margin: 0;
}

.calculation-box {
  display: none;
  height: 70px;
  width: 160px;
  position: absolute;
  bottom: 10px;
  left: 45%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
}
.features-choose {
  display: none;
  width: 200px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  overflow-y: scroll;
  text-align: center;

}

.table-container th{
  position: sticky;
  top: 0;
}

table{
  border-collapse: collapse;
  width: 100%;
}
.scroll-container.indiana-scroll-container.indiana-scroll-container--hide-scrollbars {
  max-height: 100%;
  overflow: scroll;
}
.scroll-container {
  max-height: 100%;
  overflow: scroll;
}
th{
  background: #DDD;
}
th:hover {
  background: #abf4c5;
}
td,th{
  padding: 10px;
  text-align: center;
}
.popup {
  display: none;
  height: fit-content;
  width: 300px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
  z-index: 101;
}
.infos {
  display: none;
  width: 50%;
  height: calc(100vh - 64px);
  left:50%;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 1100;
}
.georef {
  display: block;
  width:100%;
  height: 97%;
  left:0;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 1100;
}
.welcome {
  display: none;
  width: 76%;
  height: 100%;
  left:12%;
  opacity: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 9999999;
}
.input.search{
  display:block;
}
.inactive {
  width : 60%;
}
.kern {
  display: block;
  width: 50%;
  height: 100%;
  left:50%;
  opacity: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 8;
}
.wikimapia {
  display: none;
  width: 50%;
  height: 100%;
  left:50%;
  opacity: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 8;
}
.help {
  display: none;
  height: 100%;
  right:10%;
  opacity: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 8;
}
.geo3d{
  display: none;
  height: 95%;
  right:0%;
  opacity: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 8;
}
.library{
  display: block;
  height: 95%;
  right:0%;
  opacity: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 8;
}
div#geo3d iframe {
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.imageurl {
  display:none;
  left: 50%;
  top:30%;
  height: 200px;
  width: 200px;
}
.Float {
  box-shadow: 0px 4px 11.3px rgba(0, 0, 0, 0.25), 0px 33px 17.1px -22px rgba(46, 70, 83, 0.5);
}
.welcomeguide {
  display:none;
  height: 93%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 9999999;
}
.welcomebutton{
  background-color: rgba(255, 255, 255, 1)!important;
}
.loader {
  display: block;
  width: 30%;
  height: 100%;
  left:70%;
  opacity: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 9999999;
}
.geotiffloader {
  display: block;
  width: 30%;
  height: 100%;
  left:70%;
  opacity: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 9;
}
p {
  font-family: 'Open Sans';
  margin: 0;
  font-size: 13px;
}
.rightAlign {
  display: flex;
  float: right;
}
.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.MuiDialog-root{
  z-index: 999999;
}
#titlepublications {
  text-align: center;
  font-family: arial, sans-serif;
}
.menuButton{
  box-shadow: inset 105px 0 0 0 #ffffff;
  transform: scale(1);
}
.menuButton:not(.active):hover{
  box-shadow: inset 105px 0 0 0 #e6eaea;
  transform: scale(1.03);

}
.menuButton:not(.hover):active {
  box-shadow: inset 105px 0 0 0 #e6eaea;
  transform: scale(.98);
}

.addheatmap{
  display: none;
  width: 200px;
  height: 400px;
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  text-align: center;
  z-index: 9999999;
}
#publications {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#publications td, #publications th {
  border: 1px solid #ddd;
  padding: 8px;
}

#publications tr:nth-child(even){background-color: #f2f2f2;}

#publications tr:hover {background-color: #ddd;}

#students th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
}
.rct-icons-fa4 .rct-icon-collapse-all::before {
  content: "\f146";
}

.rct-icons-fa5 .rct-icon-expand-close::before {
  font-weight: 900;
  content: "\f054";
}

.rct-icons-fa5 .rct-icon-expand-open::before {
  font-weight: 900;
  content: "\f078";
}

.rct-icons-fa5 .rct-icon-uncheck::before {
  content: "\f0c8";
}

.rct-icons-fa5 .rct-icon-check::before {
  content: "\f14a";
}
.ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after {
  top:1.5px!important;
  background-color: rgba(0,0,0,.95);
}
.rct-icons-fa5 .rct-icon-half-check::before {
  opacity: .5;
  content: "\f14a";
}

.rct-icons-fa5 .rct-icon-leaf::before {
  content: "";
}


.rct-icons-fa5 .rct-icon-expand-all::before {
  content: "\f0fe";
}

.rct-icons-fa5 .rct-icon-collapse-all::before {
  content: "\f146";
}
i.icon.user-filter:after {
  content: "\f0b0";
}
.rct-text {
  display: flex;
}
.react-checkbox-tree {
  font-size: 16px;
}


.react-checkbox-tree ol {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.react-checkbox-tree ol ol {
  padding-left: 24px;
}

.react-checkbox-tree button {
  line-height: normal;
  color: inherit;
}

.react-checkbox-tree button:focus {
  outline: none;
}

.react-checkbox-tree button:disabled {
  cursor: not-allowed;
}

.react-checkbox-tree .rct-bare-label {
  cursor: default;
}

.react-checkbox-tree label {
  margin-bottom: 0;
  cursor: pointer;
}

.react-checkbox-tree label:hover {
  background: rgba(51, 51, 204, 0.1);
}

.react-checkbox-tree label:active {
  background: rgba(51, 51, 204, 0.15);
}

.react-checkbox-tree:not(.rct-native-display) input {
  display: none;
}

.react-checkbox-tree.rct-native-display input {
  margin: 0 5px;
}

.react-checkbox-tree .rct-icon {
  display: inline-block;
  text-align: center;
  text-rendering: auto;
  font-family: "Font Awesome 5 Free", "FontAwesome", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}

.rct-disabled > .rct-text > label {
  opacity: .75;
  cursor: not-allowed;
}

.rct-disabled > .rct-text > label:hover {
  background: transparent;
}

.rct-disabled > .rct-text > label:active {
  background: transparent;
}


.rct-options {
  margin-left: .5rem;
  text-align: right;
}

.rct-option {
  opacity: .75;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0 4px;
  font-size: 18px;
}

.rct-option:hover {
  opacity: 1;
}

.rct-option + .rct-option {
  margin-left: 2px;
}

.rct-collapse,
.rct-checkbox,
.rct-node-icon {
  padding: 0 5px;
}

.rct-collapse *,
.rct-checkbox *,
.rct-node-icon * {
  display: inline-block;
  margin: 0;
  width: 5px;
}

.rct-collapse {
  border: 0;
  background: none;
  line-height: normal;
  color: inherit;
  font-size: 12px;
}

.rct-collapse.rct-collapse-btn {
  cursor: pointer;
}

.rct-collapse > .rct-icon-expand-close {
  opacity: .5;
}

.rct-collapse > .rct-icon-expand-close:hover {
  opacity: 1;
}

.rct-native-display .rct-checkbox {
  display: none;
}

.rct-node-clickable {
  cursor: pointer;
}

.rct-node-clickable:hover {
  background: rgba(51, 51, 204, 0.1);
}

.rct-node-clickable:focus {
  outline: 0;
  background: rgba(51, 51, 204, 0.2);
}

.rct-node-icon {
  color: #33c;
}

.rct-title {
  padding: 0 5px;
}

.rct-icons-fa4 .rct-icon-expand-close::before {
  content: "\f054";
}

.rct-icons-fa4 .rct-icon-expand-open::before {
  content: "\f078";
}

.rct-icons-fa4 .rct-icon-uncheck::before {
  content: "\f096";
}

.rct-icons-fa4 .rct-icon-check::before {
  content: "\f046";
}

.rct-icons-fa4 .rct-icon-half-check::before {
  opacity: .5;
  content: "\f046";
}

.rct-icons-fa4 .rct-icon-leaf::before {
  content: "";
}

.rct-icons-fa4 .rct-icon-parent-open::before {
  content: "";
}

.rct-icons-fa4 .rct-icon-parent-close::before {
  content: "";
}

.rct-icons-fa4 .rct-icon-expand-all::before {
  content: "\f0fe";
}
.lasso-icon {
  height:29px;
  width:29px;
  background-color: white!important;
  position: absolute;
  right:10px;
  top:330px;
  padding: 0px;
  border-radius: 4px;
}
.net-icon {
  height:29px;
  width:29px;
  background-color: white!important;
  position: absolute;
  right:10px;
  top:370px;
  padding: 0px;
  border-radius: 4px;
}
.area-icon {
  height:29px;
  width:29px;
  background-color: white!important;
  position: absolute;
  right:10px;
  bottom:11%;
  padding: 0px;
  border-radius: 4px;
}
.route-icon {
  height:29px;
  width:29px;
  background-color: white!important;
  position: absolute;
  right:10px;
  bottom:20%;
  padding: 0px;
  border-radius: 4px;
}
.route {
  height: 400px;
  width: 400px;
  top:0%;
  right:20%;
  background-color: white!important;
  position: absolute;
}
button > img{
  vertical-align: middle;
  horiz-align: center;
}

