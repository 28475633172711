.graticule {
    position: absolute;
    width: 230px;
    display:block;
    right: 40px;
    top:280px;

    background-color: #ffffff;
    line-height:100%;
    opacity: 100%;
    background-color: hsla(0,0%,100%,.95);
    z-index: 1;
}