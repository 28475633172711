body {
    margin: 0;
    padding: 0;
}
form {
    max-width: 500px;
    margin: 0 auto;
}

h1 {
    font-weight: 100;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148);
}

.form {
    background: #ffffff;
    max-width: 400px;
    margin: 0 auto;
}

p {
    color: #bf1650;
}

p::before {
    display: inline;
    content: "⚠ ";
}

UL {
    list-style-type: none; /* нету */
    color: black; /* Цвет текста списка */
    width: 240px;
}
input[type='checkbox'] {
    float: right;
    width: 20px;
}
input[type='checkbox'] + label {
    display: block;
    width: 30px;
}
