.mapboxgl-ctrl-group{
    float: right;
    margin: 10px 10px 0 0;
}
.myGeoMap-wrap {
    position: relative;
    width: 100%;
    height: calc(100vh - 64px); /* calculate height of the screen minus the heading */
}

.myGeoMap {
    position: absolute;
    width: 100%;
    height: 100%;
}
.lasso-icon2 {
    height:29px;
    width:29px;
    background-color: white!important;
    position: absolute;
    right:10px;
    top:410px;
    padding: 0px;
    border-radius: 4px;
    z-index: 1;
}
.area-icon {
    height:29px;
    width:29px;
    background-color: white!important;
    position: absolute;
    right:10px;
    bottom:110px;
    padding: 0px;
    border-radius: 4px;
    z-index: 1;
}
.maplibregl-ctrl-scale {
    position: relative;
    bottom: -10px;
}
.latlngnew {
    position: absolute;
    width: 135px;
    display:table;
    right: 100px;
    bottom: 45px;
    height: 20px;
    line-height: 100%;
    opacity: 100%;
    background-color: hsla(0,0%,100%,.75);
    font-size: 10px;
    border: 2px solid #333;
    border-top: #333;
    padding:5px;
    color: #333;
    box-sizing: border-box;
    text-align: center;
    z-index: 1;
}