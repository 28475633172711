.reset-style, .reset-style * {
    all: revert;
}
input[type="range"][orient=vertical].userVertical {
    all: revert;
    padding: 0px 8px;
}
input[type="range"].userHorizontal {
    all: revert;
}
input[type=range][orient=vertical].userVertical::-webkit-slider-thumb {
    padding: 8px 0px;
    margin: 0px -3px;
    -webkit-appearance : none;
    background : red;
    height : 5px;
    width : 5px;
}
input[type=range]::-webkit-slider-thumb {
    padding: 0px 0px;
    margin: -1px 0px;
    -webkit-appearance : none;
    background : blue;
    height : 8px;
    width : 8px;
}
.br-nospace{
    display: block;
    content: "";
    margin-top: 0;
}