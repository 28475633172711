.Float {
    position: absolute;
    background: white;
    padding: 6px;
    opacity: 0.98;
    width: 50%;
    height: calc(100% - 64px);
    left:25%;
    display: block;
    z-index: 9999999;
    resize: both;
    overflow:hidden;
}
.LasTool.both {
    resize: both;
}
.dropdown-choose-research2{
    transform: translateY(11px)!important;
}
.ui.multiple.dropdown>.label {
    vertical-align: baseline;
}
.slider-x-axis {
    top:7px!important;
}
.label-no-border {
    border:none!important;
}
.input-short {
    width : 100px;
}
.button-no-border{
    box-shadow: none !important;
    border-radius:0px !important;
    color: transparent !important;
}