.reserves {
    position: absolute;
    padding-left: 30px;
    left:10%;
    bottom:0;
    height: 100%;
    background-color: #ffffff;
    line-height:100%;
    opacity: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1800;
    overflow-y: scroll;
}
.ui, .visible.menu.transition {
    &.dropdown {
        z-index: 10000;
    }
}
.popup {
    z-index: 9999999;
}
*.size75div {
    padding: 20px;
    display: inline;
    margin-right: -10px;
}
*.size75div2 {
    display: inline;
    margin-right: -15px;
}
*.size75div3 {
    display: inline;
    padding-left: 14px;
}
*.size75Button {
    transform: scaleX(0.70)scaley(0.70)translateX(-35%)translatey(10%);
    width:110px;
    transform-origin: left top;
}
*.size75input {
    transform: scaleX(0.85)scaley(0.70)translateX(-25%)translatey(10%);
    width:100px;
    transform-origin: left top;
}
*.size75label1 {
    transform: scale(0.85);
    width:140px;
    text-align: center;
    transform-origin: left top;
}
*.size75label3 {
    transform: scale(0.85);
    width:200px;
    text-align: center;
    transform-origin: left top;
}
.ui.basic.table tbody tr {
    border-bottom: 0px !important;
}
.ui.table tr td
{
    border-top: 0px !important;
}
p {
    color: #9f3a38;
}
*.size75label2 {
    transform: scale(0.85)translateX(-20%);
    width:77px;
    text-align: center;
    transform-origin: left top;
}
*.size75dropdown {
    transform-origin: left top;
    transform: scale(0.85)translateX(-7.5%)!important;
}