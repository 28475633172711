input[type=range] {
    overflow: hidden;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 80%;
    outline: none;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    /*box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;*/
    background: #c0c0c0;
    border-radius: 1.3px;
    border: 0.2px solid #010101;

}
input[type=range]::-webkit-slider-thumb {
    border: 1px solid #D9D9D9;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: #F9F9F9;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #c0c0c0;
}
