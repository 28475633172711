.sz90 {
    transform: scale(0.90);
    cursor: pointer;
}
.sz90corr {
    position: relative;
    right:4px!important;
    transform: scale(0.90);
    cursor: pointer;
}
.ui.basic.label:hover {
    background-color: darkgrey;
}